<template>
  <q-page padding>

    <h5>Política de Privacidade</h5>
    <p>Só Frases, reconhece que a privacidade é importante.</p>
    <p>Só Frases recebe e mantém informações de seu browser nos nossos servidores, incluindo seu IP ou endereço de referência e a página que você procurou. Além desta informação, a única outra informação pessoal que nós coletamos sobre você é a que nos for fornecida em nossos formulários online. Pensador será a única dententora desta informação. Esta informação somente será usada para nosso acompanhamento estatístico do número de visitas.</p>
    <p>Só Frases usa cookies e outras tecnologias para melhorar a sua experiência on-line e para saber como você usa nossos serviços, com a finalidade de melhorar a qualidade deles.</p>
    <p>Usamos empresas de publicidade de terceiros para veicular anúncios durante a sua visita ao nosso website. Essas empresas podem usar informações (que não incluem o seu nome, endereço, endereço de e-mail ou número de telefone) sobre suas visitas a este e a outros websites a fim de exibir anúncios relacionados a produtos e serviços de seu interesse. Para obter mais informações sobre essa prática e saber como impedir que as empresas utilizem esses dados, <a href="https://policies.google.com/technologies/ads">clique aqui</a>.</p>
    <p class="text-h6">Anúncios Google</p>
    <p>O Google, como fornecedor de terceiros, utiliza cookies para exibir anúncios no seu site.
      Com o cookie DART, o Google pode exibir anúncios para seus usuários com base nas visitas feitas aos seus e a outros sites na Internet.
      Os usuários podem desativar o cookie DART visitando a Política de privacidade da rede de conteúdo e dos anúncios do Google.</p>
    <p class="text-h6">Desativar Cookies no Site</p>
    <p>Para rever e/ou alterar as opções do consentimento relativas a cookies, <a href="/politica-privacidade">clique aqui</a>.</p>
    <p class="text-h6">Newsletter</p>
    <p>Só Frases poderá tratar os seus dados para lhe enviar frases, pensamentos e inspiração.</p>
    <p>Este tratamento de dados será realizado apenas com o seu consentimento, através de formulários de “Newsletter” no website. O consentimento para o tratamento de dados pessoais para efeitos de newsletter pode ser revogado em qualquer altura através de um link no final de todos os emails.</p>
    <p class="text-h6 text-caption">Esta política pode ser atualizada sem aviso prévio, portanto revise-a periodicamente para assegurar-se de estar informado sobre estas mudanças.</p>
    <q-page-sticky
      position="bottom-right"
      :offset="[18, 18]"
    >
      <q-btn
        fab
        icon="arrow_back"
        color="info"
        padding="md"
        :to="{name: 'home'}"
      >
        <q-tooltip>
          Voltar
        </q-tooltip>
      </q-btn>
    </q-page-sticky>
  </q-page>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PagePoliticaPrivacidade',

  setup () {
    return {}
  }
})
</script>
